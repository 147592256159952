body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-1e1e1e {
    background-color: #1e1e1e;
  }

  .bg-2d2d2d {
    background-color: #2d2d2d;
  }

  .bg-282828 {
    background-color: #282828;
  }

  .bg-midas-neutral-500 {
    background-color: #d9d9d9;
  }

  .bg-2e2e2e {
    background-color: #2e2e2e;
  }

  .bg-midas-neutral-900 {
    background-color: #020202;
  }

  .bg-sidebar {
    background-color: #020202;
    @apply opacity-90;
  }

  .bg-white-45 {
    background-color: #ffffff73;
  }

  .bg-portfolio {
    background: linear-gradient(110.46deg,
        rgba(255, 255, 255, 0.98) -12.66%,
        rgba(255, 255, 255, 0.882) 153.65%,
        rgba(255, 255, 255, 0) 159.39%);
  }

  .bg-midas-price {
    background: linear-gradient(105.35deg,
        rgba(255, 255, 255, 0.9) -1.3%,
        rgba(255, 255, 255, 0.846) 100.99%);
  }

  .color-020202 {
    color: #020202;
  }

  .color-brand-yellow {
    color: #ffdd28;
  }

  .color-yellow {
    background-color: #ffdd28;
  }

  .color-midas-neutral-900 {
    --tw-text-opacity: 1;
    color: rgb(2 2 2 / var(--tw-text-opacity));
  }

  .color-midas-neutral-800 {
    color: #2e2e2e;
  }

  .color-midas-neutral-500 {
    color: #d9d9d9;
  }

  .color-2d2d2d {
    color: #2d2d2d;
  }

  .color-e7e7e7 {
    color: #e7e7e7;
  }

  .color-333333 {
    color: #333333;
  }

  .color-8692A6 {
    color: #8692a6;
  }

  .color-white-70 {
    color: #ffffffb2;
  }

  .border-midas-neutral-500 {
    border-color: #d9d9d9;
  }

  .border-282828 {
    border-color: #282828;
  }

  .border-brand-yellow {
    border-color: #ffdd28;
  }

  .text-form-title {
    color: #2d2d2d;
    @apply font-bold text-2xl;
  }

  .text-form-label {
    @apply font-normal text-base color-midas-neutral-800;
  }

  .form-input {
    border-color: #d9d9d9;
    @apply border rounded-md w-full text-sm p-4 bg-white;
  }

  .visibility-hidden {
    visibility: hidden;
  }
}

@layer components {
  .button-gray {
    @apply bg-gray-500 rounded-lg px-5 py-2 md:px-6 md:py-2 text-white text-sm font-bold;
  }

  .button-yellow {
    background-color: #ffdd28;
    @apply rounded-lg px-5 py-2 md:px-6 md:py-2 color-020202 text-sm font-bold;
  }

  /*
   additional if needed :
    hover:color-yellow hover:shadow-lg hover:text-black
    focus:color-yellow focus:shadow-lg focus:outline-none focus:ring-0 focus:text-black
    active:color-yellow active:shadow-lg active:text-black
     */

  .midas-menu-item {
    @apply px-4 py-2.5 text-white font-bold text-sm rounded shadow-md transition duration-150 ease-in-out flex items-center whitespace-nowrap;
  }

  .responsive-nav-menu {
    @apply absolute right-0 top-12 lg:flex lg:relative lg:top-0 lg:justify-center;
  }

  .responsive-nav-menu.active {
    @apply block bg-midas-neutral-900 lg:flex;
  }

  .responsive-nav-menu.inactive {
    @apply hidden lg:flex;
  }

  .user-profile-container {
    @apply bg-2e2e2e p-8 rounded-3xl;
  }

  /* Toast */
  /* Notification */
  .notification-container {
    @apply m-0 fixed box-border text-sm;
  }

  .notification-container button {
    right: -0.3em;
    top: -0.3em;
    color: #ffffff;
    outline: none;
    border: none;
    text-shadow: 0 1px 0 #fff;
    line-height: 1;
    font-size: 14px;
    background: 0 0;
    border: 0;
    @apply relative float-right font-bold opacity-80 p-0 cursor-pointer;
  }

  .notification {
    @apply bg-white relative pointer-events-auto overflow-hidden mb-4 p-8 w-80 rounded shadow-md shadow-gray-400 opacity-90 hover:shadow-white hover:shadow-lg hover:opacity-100 hover:cursor-pointer;
  }

  .notification-title {
    @apply font-bold text-base text-left mt-0 mb-2 w-80;
  }

  .notification-message {
    margin-left: -1px;
    @apply m-0 text-left overflow-hidden text-ellipsis whitespace-nowrap;
  }

  .notification-image {
    @apply float-left mr-4 w-8 h-8;
  }

  /* Toast */
  .toast {
    color: #ffffff;
    padding: 20px 15px 10px 10px;
    @apply w-[365px];
  }

  .toast-top-right {
    @apply top-3 right-3 ease-in-out duration-500;
  }

  .toast-top-left {
    @apply top-3 left-3 ease-in duration-500;
  }

  .toast-bottom-left {
    @apply bottom-3 left-3 ease-in duration-500;
  }

  .toast-bottom-right {
    @apply bottom-3 right-3 ease-in-out duration-500;
  }

  .toast-success {
    @apply bg-green-500;
  }

  .toast-danger {
    @apply bg-red-500;
  }

  .toast-info {
    @apply bg-blue-400;
  }

  .toast-warning {
    @apply bg-yellow-500;
  }

  /* form */
  .input-form {
    @apply w-96 border border-282828 rounded-md px-6 py-4 bg-282828 mt-3 text-sm;
  }

  .responsive-header-title {
    @apply text-3xl lg:text-5xl;
  }

  .portfolio-container {
    @apply bg-portfolio mx-7 md:mx-16 rounded-3xl p-14 mt-16;
  }

  .jarvis-container-small {
    width: calc(100vw - 24px);
  }

  .jarvis-container {
    width: calc(100vw - 324px);
  }

  .showcase-container {
    --calculatedWidth: calc(100vw / 2);
    height: calc(var(--calculatedWidth) / 2);
  }

  .showcase-container-small {
    height: calc(100vw / 2);
  }
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 85px;
  right: 15px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 99;
}

.my-float {
  z-index: 100;
  margin-top: 0px;
}

.tscroll {

}

.tscroll table td:first-child {
  border: solid #dee2e6 !important;
  position: sticky;
  left: 0;
  background-color: white;
  font-weight: bold;
  
}


h4{
  color:#ffdd28;
  background-color : #020202;
  font-weight: bold;
}

h4, .ql-size-huge{
  font-size: 25px;
}

blockquote{
  display: block;
  border-left-width: 5px;
  border-left-color: grey;
  padding-left: 10px;
}

.ql-li{
  padding-left:50px;
}

.ql-link{
  color:blue;
  text-decoration: underline;
}

.ql-link:hover{
  color: #ffdd28;
  -webkit-text-stroke-width: .3px;
  -webkit-text-stroke-color: #020202;
  text-decoration-color: #ffdd28;
}

ol.ql-li{
  list-style: decimal;
}

ul.ql-li{
  list-style: disc;
}

.ql-size-small{
  font-size:10px;
}

.ql-size-medium{
  font-size: 15px;
}

.ql-size-large{
  font-size: 20px;
}

.ql-indent-1{
  margin-left: 40px;
}

.ql-align-left{
  text-align: left;
}

.ql-align-center{
  text-align: center;
}

.ql-align-right{
  text-align: right;
}

.ql-align-justify{
  text-align: justify;
}

.wa-long{
	position:fixed;
	width:fit;
	height:fit;
	bottom:100px;
	right:25px;
	padding:15px;
	background-color:white;
	border:10px;
	border-color:black;
	border-radius:30px;
	text-align:center;
	box-shadow: 0 0 10px black;
	z-index:100;
}